import React, { Component } from "react";
import { withRouter } from "react-router";
import { Form, Button, Col } from 'react-bootstrap';
import Spinner from 'react-spinkit';
import axios from 'axios';
import PasswordChecklist from "react-password-checklist"

import "../css/Home.css";

class PasswordReset extends Component {

  constructor(props) {
    super(props);

    this.state = {
      code: null,
      username: null,
      errorInviteCode: false,
      errorPasswordReset: false,
      errorPwPolicy: false,
      loading: false,
      submit: false,
      password1: '',
      password2: '',
      passwordValid: false,
      success: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  componentDidMount() {
    const code = this.props.match.params.code;

    this.setState({
      loading: true,
    }, () => {
      let data = {
        code: code
      };
      axios.post(this.props.endpoints.passwordResetValidateInvite, data)
      .then(response => {
        this.setState({
          code: response.data.code,
          username: response.data.username,
          loading: false,
        });
      }).catch(error => {
        this.setState({
          errorInviteCode: true,
          loading: false,
        });
      });
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  resetPassword(e) {
    e.preventDefault();
    this.setState({
      loading: true,
      submit: true,
    }, () => {
      let data = {
        code: this.state.code,
        username: this.state.username,
        password: this.state.password1,
      };
      axios.post(this.props.endpoints.passwordReset, data)
        .then(response => {
          this.setState({
            loading: false,
            success: true,
            errorPasswordReset: false,
            errorPwPolicy: false,
          });
        }).catch(error => {
          this.setState({
            loading: false,
            submit: false,
            success: false,
            password1: '',
            password2: '',
            errorPasswordReset: true,
            errorPwPolicy: error.response.status === 409 ? true : false,
          });
        });
    });
  }

  renderSuccessMessage() {
    return (
      <Col md={4} className="mx-auto text-center">
        {
          <div className="alert alert-success" role="alert">
            <h4 className="alert-heading">Success!</h4>
            <p className="text-left">You ANT Active Directory password has been successfully changed.</p>
          </div>
        }
      </Col>
    )
  }

  renderInvalidInvite() {
    return (
      <Col md={4} className="mx-auto text-center">
        {
          <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading">Whoops!</h4>
            <p className="text-left">We can't find your ANT password reset invitation code. You may <a href="/">request a new invitation</a>.</p>
          </div>
        }
      </Col>
    )
  }

  renderPasswordForm() {
    return (
      <>
        <p>Enter your new password, then re-enter to confirm</p>
        <Col md={4} className="mx-auto text-center">
          {
            this.state.errorPasswordReset ?
              <div className="alert alert-danger" role="alert">
                <h4 className="alert-heading">Error</h4>
                {
                  this.state.errorPwPolicy ?
                    <p className="text-left">After a deep analysis of your password, it does not appear to meet <a target="_blank" rel="noreferrer noopener" href="https://docs.microsoft.com/en-us/windows/security/threat-protection/security-policy-settings/password-must-meet-complexity-requirements">Microsoft AD Guidelines</a>. Please try again.</p>
                  :
                    <p className="text-left">There was an error resetting your password. Your invitation code may have expired. You may <a href="/">request a new invitation</a>.</p>
                }
              </div>
              :
              <>
                <Form onSubmit={this.resetPassword}>
                  <Form.Group className="mb-2">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter new password"
                      id="password1"
                      name="password1"
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Re-enter New Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm new password"
                      id="password2"
                      name="password2"
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                  <PasswordChecklist
                    rules={["minLength", "maxLength", "specialChar", "number", "capital", "match"]}
                    minLength={8}
                    maxLength={64}
                    value={this.state.password1}
                    valueAgain={this.state.password2}
                    onChange={(isValid) => {
                      this.setState({
                        passwordValid: isValid
                      });
                    }}
                  />
                  <Button className="mt-3" variant="primary" type="submit" size="lg" disabled={!this.state.passwordValid}>
                    Reset Password
                  </Button>
                </Form>
              </>
          }
        </Col>
      </>
    );
  }

  renderLoading() {
    return (
      <div className="mx-auto row mt-5" style={{ width: 75 }}>
        <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" className="mt-8" />
      </div>
    );
  }

  render() {
    return (
      <div className="Home">
        <div className="lander">
          <h1>ANT Password Reset</h1>
          {this.state.loading && this.renderLoading()}
          {!this.state.loading && this.state.errorInviteCode && this.renderInvalidInvite()}
          {!this.state.loading && this.state.username && this.state.code && !this.state.submit && this.renderPasswordForm()}
          {!this.state.loading && this.state.success && this.renderSuccessMessage()}
        </div>
      </div>
    );
  }
}

export default withRouter(PasswordReset);