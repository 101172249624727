import React, { Component } from "react";
import { Form, Button, Row, Col } from 'react-bootstrap';
import Spinner from 'react-spinkit';
import axios from 'axios';

import "../css/Home.css";

export default class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      error: false,
      loading: false,
      submit: false,
      submitted: false,
      validated: true,
      username: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.requestReset = this.requestReset.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      validated: !event.target.value.includes('@')
    });
  }

  requestReset() {
    this.setState({
      submit: true,
      loading: true,
      error: false,
    }, () => {
      let data = {
        'username': this.state.username
      };
      axios.post(this.props.endpoints.passwordResetSendInvite, data)
        .then(response => {
          this.setState({
            submitted: true,
            loading: false,
          });
        }).catch(error => {
          this.setState({
            error: true,
            submit: false,
            loading: false,
          });
        });
    });
  }

  renderForm() {
    return (
      <>
        <p>Enter your ANT Active Directory username</p>
        <Col md={4} className="mx-auto text-center">
          {
            this.state.error &&
            <div className="alert alert-danger" role="alert">
              <h4 className="alert-heading">Unexpected Error</h4>
              <p className="text-left">An error occurred when submitting your password reset request. Please try again.</p>
            </div>
          }
          <Form onSubmit={this.requestReset} noValidate>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                maxLength="20"
                isInvalid={!this.state.validated}
                placeholder="Enter username"
                id="username"
                name="username"
                value={this.state.username}
                onChange={this.handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please supply only your AD username, not email address.
              </Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit" size="lg" disabled={this.state.username === null || this.state.username === '' || this.state.submit || !this.state.validated}>
              Submit
            </Button>
          </Form>
        </Col>
      </>
    );
  }

  renderLoading() {
    return (
      <div className="mx-auto row mt-5" style={{ width: 75 }}>
        <Spinner name="three-bounce" color="#ab031f" fadeIn="quarter" className="mt-8" />
      </div>
    );
  }

  renderSubmitMessage() {
    return (
      <Col md={4} className="mx-auto text-left">
        <div className="alert alert-info" role="alert">
          <p>Your password reset request has been submitted. If your username is valid you will receive an email momentarily, containing a link to reset your password.</p>
        </div>
      </Col>
    )
  }

  render() {
    return (
      <div className="Home">
        <div className="lander">
          <h1>ANT Password Reset</h1>
          {!this.state.submitted && !this.state.loading && this.renderForm()}
          {this.state.submitted && this.renderSubmitMessage()}
          {this.state.loading && this.renderLoading()}
        </div>
      </div>
    );
  }
}
