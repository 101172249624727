import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Home from "./containers/Home";
import PasswordReset from "./containers/PasswordReset";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";

export default ({ childProps }) => {
    return (
        <BrowserRouter>
            <Switch>
                <AppliedRoute path="/" exact component={Home} props={childProps} />
                <AppliedRoute path="/:code" exact component={PasswordReset} props={childProps} />
                <Route component={NotFound} />
                {/* <AppliedRoute path="/login" exact component={Login} props={childProps} /> */}
            </Switch>
        </BrowserRouter>
    )
}
 